// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-education-services-js": () => import("./../../../src/pages/educationServices.js" /* webpackChunkName: "component---src-pages-education-services-js" */),
  "component---src-pages-guardianship-js": () => import("./../../../src/pages/guardianship.js" /* webpackChunkName: "component---src-pages-guardianship-js" */),
  "component---src-pages-homestay-application-js": () => import("./../../../src/pages/homestay_application.js" /* webpackChunkName: "component---src-pages-homestay-application-js" */),
  "component---src-pages-homestay-js": () => import("./../../../src/pages/homestay.js" /* webpackChunkName: "component---src-pages-homestay-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-services-js": () => import("./../../../src/pages/supportServices.js" /* webpackChunkName: "component---src-pages-support-services-js" */),
  "component---src-pages-workwithus-js": () => import("./../../../src/pages/workwithus.js" /* webpackChunkName: "component---src-pages-workwithus-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

